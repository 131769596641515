import React from "react"
import pictureBarcelona from "../assets/images/carousel/barcelona.png"
import pictureAveiro from "../assets/images/carousel/avio.jpeg"
import pictureHelsinki from "../assets/images/carousel/helsinki.jpeg"

const defaultPictures = [pictureBarcelona, pictureHelsinki, pictureAveiro]

function Slider({ className = "", pictures = defaultPictures, id = "" }) {
  return (
    <div
      id={`indexCarousel-${id}`}
      className={`carousel slide carousel-fade relative w-1/2 ${className}`}
      data-bs-ride="carousel"
    >
      <div className="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
        {pictures.map((picture, index) => (
          <button
            type="button"
            data-bs-target={`#indexCarousel-${id}`}
            data-bs-slide-to={index}
            className={index == 0 ? "active" : ""}
            aria-current={index == 0 ? "true" : "false"}
            aria-label={`Slide ${index + 1}`}
          ></button>
        ))}
      </div>
      <div className="carousel-inner relative w-full overflow-hidden">
        {pictures.map((picture, index) => (
          <div
            className={`carousel-item float-left w-full rounded-xl ${
              index == 0 ? "active" : ""
            }`}
          >
            <img
              src={picture}
              className="block w-full rounded-xl"
              alt="City of Aveiro"
            />
          </div>
        ))}
      </div>
      <button
        className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
        type="button"
        data-bs-target={`#indexCarousel-${id}`}
        data-bs-slide="prev"
      >
        <span
          className="carousel-control-prev-icon inline-block bg-no-repeat"
          aria-hidden="true"
        ></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
        type="button"
        data-bs-target={`#indexCarousel-${id}`}
        data-bs-slide="next"
      >
        <span
          className="carousel-control-next-icon inline-block bg-no-repeat"
          aria-hidden="true"
        ></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  )
}

export default Slider
