import React from "react"
import SEO from "../seo"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircle } from "@fortawesome/free-solid-svg-icons"
import { FormattedMessage, useIntl } from "react-intl"
import compassFullLogo from "../../assets/images/layout/logo_full.png"
import digiLearning from "../../assets/images/index/digital-learning.svg"
import onlineResources from "../../assets/images/index/online_resources.svg"
import compassTeaching from "../../assets/images/index/compass_teaching.svg"
import timelineImg from "../../assets/images/index/timeline.svg"
import educationBooks from "../../assets/images/index/education-books.svg"
import logoZini from "../../assets/images/logos/zini.png"
import logoDante from "../../assets/images/logos/dante.png"
import logoDomSpain from "../../assets/images/logos/domSpain.jpg"
import logoKhs from "../../assets/images/logos/khs.png"
import logoPolygonal from "../../assets/images/logos/polygonal.png"
import logoUa from "../../assets/images/logos/ua.png"
import logoUnave from "../../assets/images/logos/unave.png"
import onlineTest from "../../assets/images/index/test_online.svg"
import agendaKickOffMeeting from "../../assets/timeline/agenda_kick_of_meeting.pdf"
import kickOfPicture1 from "../../assets/timeline/kick_of_1.jpg"
import kickOfPicture2 from "../../assets/timeline/kick_of_2.jpg"
import agendaTM2 from "../../assets/timeline/tm2_agenda.pdf"
import TM2Picture1 from "../../assets/timeline/tm2_1.jpg"
import TM2Picture2 from "../../assets/timeline/tm2_2.jpeg"
import TM2Picture3 from "../../assets/timeline/tm2_3.jpeg"
import TM2Picture4 from "../../assets/timeline/tm2_4.jpeg"
import TM3Agenda from "../../assets/timeline/tm3_agenda.pdf"
import TM3Picture1 from "../../assets/timeline/tm3_1.jpg"
import TM3Picture2 from "../../assets/timeline/tm3_2.jpg"
import TM3Picture3 from "../../assets/timeline/tm3_3.jpg"
import TM3Picture4 from "../../assets/timeline/tm3_4.jpg"
import TM3Picture5 from "../../assets/timeline/tm3_5.jpg"
import TM4Agenda from "../../assets/timeline/tm4_agenda.pdf"
import TM4Picture1 from "../../assets/timeline/tm4_1.jpeg"
import TM5Agenda from "../../assets/timeline/tm5_agenda.pdf"
import TM5Picture1 from "../../assets/timeline/tm5_1.jpg"
import TM5Picture2 from "../../assets/timeline/tm5_2.jpg"
import TM5Picture3 from "../../assets/timeline/tm5_3.jpg"
import digitalBookEn from "../../assets/digital-book/digital-book-en.pdf"
import digitalBookEs from "../../assets/digital-book/digital-book-es.pdf"
import digitalBookIt from "../../assets/digital-book/digital-book-it.pdf"
import digitalBookLv from "../../assets/digital-book/digital-book-lv.pdf"
import digitalBookDe from "../../assets/digital-book/digital-book-de.pdf"
import digitalBookHr from "../../assets/digital-book/digital-book-hr.pdf"
import digitalBookPt from "../../assets/digital-book/digital-book-pt.pdf"
import MEItalyReport from "../../assets/timeline/me_italy_report.pdf"
import MEItalyPicture1 from "../../assets/timeline/me_italy1.jpeg"
import MEItalyPicture2 from "../../assets/timeline/me_italy2.png"
import MEItalyPicture3 from "../../assets/timeline/me_italy3.jpeg"
import MEItalyPicture4 from "../../assets/timeline/me_italy4.jpeg"
import MEItalyPicture5 from "../../assets/timeline/me_italy5.jpeg"
import MEItalyPicture6 from "../../assets/timeline/me_italy6.jpeg"
import MEGermanyPicture1 from "../../assets/timeline/me_germany1.jpg"
import MEGermanyPicture2 from "../../assets/timeline/me_germany2.jpg"
import MEGermanyPicture3 from "../../assets/timeline/me_germany3.jpeg"
import MEPortugalPicture1 from "../../assets/timeline/me_portugal1.jpeg"
import MEPortugalPicture2 from "../../assets/timeline/me_portugal2.jpeg"
import MEPortugalPicture3 from "../../assets/timeline/me_portugal3.jpeg"
import Slider from "../slider"
import Waves from "../waves"
import CustomModal from "../custom-modal"

const imagesTM1 = [kickOfPicture1, kickOfPicture2]
const imagesTM2 = [TM2Picture1, TM2Picture2, TM2Picture3, TM2Picture4]
const imagesTM3 = [
  TM3Picture2,
  TM3Picture4,
  TM3Picture1,
  TM3Picture3,
  TM3Picture5,
]
const imagesTM4 = [TM4Picture1]
const imagesTM5 = [TM5Picture1, TM5Picture2, TM5Picture3]

const imagesMEAll = [
  MEItalyPicture1,
  MEItalyPicture3,
  MEItalyPicture6,
  MEItalyPicture2,
  MEItalyPicture5,
  MEItalyPicture4,
  MEGermanyPicture2,
  MEGermanyPicture3,
  MEGermanyPicture1,
  MEPortugalPicture1,
  MEPortugalPicture2,
  MEPortugalPicture3,

]

function IndexPageComponent({ langKey }) {
  const intl = useIntl()
  const digitalBook = {
    de: digitalBookDe,
    en: digitalBookEn,
    es: digitalBookEs,
    hr: digitalBookHr,
    it: digitalBookIt,
    lv: digitalBookLv,
    pt: digitalBookPt,
  }

  return (
    <>
      <SEO title="Home" />
      {/* Hero */}
      <section className=" pt-32 pb-24">
        <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row justify-center">
          <div className="flex flex-col w-full justify-center items-start text-center  pt-10">
            <p className="uppercase tracking-wide w-full">
              <FormattedMessage id="Project number 2020-1-DE02-KA204-007391" />
            </p>
            {/* <h1 className="my-4 text-5xl font-bold leading-tight w-full">
                COMPASS:
              </h1> */}
            {/* <p className="leading-normal text-2xl mb-8">
                enhancing motivation of older learners to learn through cultural
                materials
              </p> */}
            <img
              className="w-3/6 md:w-2/6 rounded mx-auto my-4 hide-bg"
              src={compassFullLogo}
              alt="Compass full logo with slogan"
            />
            {/* <p className=" w-5/6 md:w-4/6 lg:w-3/6 p-2 md:p-3 mx-auto uppercase bg-white  md:text-3xl font-bold text-green-compass  text-center border-4 border-green-900 rounded-md  ">
                enhancing motivation of older learners to learn through cultural
                materials
              </p> */}


          </div>
          <Slider className="pt-10" />

          {/* 
            <div className="w-full md:w-3/5 py-6 text-right ">
              <p className="uppercase text-green-600 bg-white text-center border rounded-sm text-2xl ">
                enhancing motivation of older learners to learn through cultural
                materials
              </p>{" "}
            </div> */}
        </div>
      </section>

      {<Waves />}

      {/* Alternate features */}
      <section id="the-project" className="bg-white border-b py-8">
        <div className="container max-w-5xl mx-auto m-8 leading-relaxed">
          <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            <FormattedMessage id="The Project" />
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>

          <div className="flex flex-wrap">
            <div className="w-5/6 sm:w-1/2 p-6">
              <p className="text-gray-600 mb-8">
                <FormattedMessage id="COMPASS is an initiative born from the need identified in all partner countries to motivate older learners to take a more active part in lifelong learning. In our current globalized world foreign languages are an essential vehicle to ensure communication among societies. In parallel, it is of human nature to feel identified and represented by a culture, a society, a community; these define our values, beliefs and way of relating to the others and the world. Being able to express such identifications by story-telling and creating of narratives in foreign languages is indeed a source of motivation and inspiration." />
              </p>
            </div>
            <div className="w-full sm:w-1/2 p-6">
              <img src={digiLearning} alt="" />
            </div>
          </div>

          <div className="flex flex-wrap flex-col-reverse sm:flex-row">
            <div className="w-full sm:w-1/2 p-6 mt-6">
              <img src={compassTeaching} alt="" />
            </div>
            <div className="w-full sm:w-1/2 p-6 mt-6">
              <div className="align-middle">
                {/* <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                    Styled with Tailwind CSS
                  </h3> */}
                <p className="text-gray-600 mb-8">
                  <FormattedMessage id="As an educator, the COMPASS project will help you combine cultural heritage with foreign language learning in a very creative way and with a CLIL approach. You will learn how to embed digital practices in your educational activities, experiment with cultural narratives in our digital books and find out about cultural heritage sites thanks to our geocaching routes! " />
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="w-5/6 sm:w-1/2 p-6">
              <p className="text-gray-600 mb-8">
                <FormattedMessage id="COMPASS materials will help you extend your professional competences and be more able to motivate older people to engage in lifelong learning." />
              </p>
              <p className="text-gray-600 mb-8">
                <FormattedMessage id="Start browsing around all our web sections on this platform and find out all materials and COMPASS project has to offer you!" />
              </p>
            </div>
            <div className="w-full sm:w-1/2 p-6">
              <img src={onlineResources} alt="" />
            </div>
          </div>
        </div>
      </section>

      {/* Timeline */}
      <section id="timeline" className="bg-white border-b py-8">
        <div className="container mx-auto flex flex-wrap pt-4 pb-12">
          <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            <FormattedMessage id="TIMELINE" />
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          <div className="relative grid mt-5 justify-center justify-items-center px-5 text-black ">
            <img src={timelineImg} className="w-96" alt="" />
          </div>
          <div className="relative w-full mt-5 ml-10 md:mx-auto lg:w-auto  text-left text-black ">
            <div className="flex items-center relative">
              <div className="hidden md:block w-20">
                <div className="font-bold italic">2021</div>
                <div className="md:flex space-x-1 text-xs">
                  <div>
                    <FormattedMessage id="September" />
                  </div>
                </div>
              </div>

              <div className="border-r-2 border-black absolute h-full left-1 md:left-20 top-2 z-10">
                <FontAwesomeIcon
                  icon={faCircle}
                  size="1x"
                  className="fas fa-circle -top-2 -ml-2 absolute"
                />
              </div>

              <div className="ml-10">
                <div className="font-bold">
                  <FormattedMessage id="Online Kick-off meeting: January 2021" />
                </div>
                <div className="italic md:mb-4 underline">
                  <a href={agendaKickOffMeeting} target="_blank">
                    <FormattedMessage id="Agenda" />
                  </a>
                  <br />
                  <CustomModal pictures={imagesTM1} id="tm1" />
                </div>
                <div className="italic md:mb-4"></div>
                <div className="mb-4 mt-2 md:hidden">
                  <div className="font-bold">2021</div>
                  {/* <div className="text-xs">Abril - Junio</div> */}
                </div>
                {/* <div className="mb-10">DEscription of the project</div> */}
                <div className="mb-10"></div>
              </div>
            </div>
            <div className="flex items-center relative">
              <div className="hidden md:block w-20">
                <div className="font-bold italic">2021</div>
                <div className="md:flex space-x-1 text-xs">
                  <div>
                    <FormattedMessage id="November" />
                  </div>
                </div>
              </div>

              <div className="border-r-2 border-black absolute h-full left-1 md:left-20 top-2 z-10">
                <FontAwesomeIcon
                  icon={faCircle}
                  size="1x"
                  className="fas fa-circle -top-2 -ml-2 absolute"
                />
              </div>

              <div className="ml-10">
                <div className="font-bold">
                  <FormattedMessage id="Second Transnational meeting: Ogre (Latvia)  " />
                </div>
                <div className="italic md:mb-4 underline">
                  <a href={agendaTM2} target="_blank">
                    <FormattedMessage id="Agenda" />
                  </a>
                  <br />
                  <CustomModal pictures={imagesTM2} id="tm2" />
                </div>
                <div className="italic md:mb-4"></div>
                <div className="mb-4 mt-2 md:hidden">
                  <div className="font-bold">2021</div>
                  <div className="text-xs">
                    <FormattedMessage id="November" />
                  </div>
                </div>
                {/* <div className="mb-10">DEscription of the project</div> */}
                <div className="mb-10"></div>
              </div>
            </div>
            {/* Timeline - tool bank */}
            <div className="flex items-center relative">
              <div className="hidden md:block w-20">
                <div className="font-bold italic">2021</div>
                <div className="md:flex space-x-1 text-xs">
                  <div>
                    <FormattedMessage id="July" />
                  </div>
                </div>
              </div>

              <div className="border-r-2 border-black absolute h-full left-1 md:left-20 top-2 z-10">
                <FontAwesomeIcon
                  icon={faCircle}
                  size="1x"
                  className="fas fa-circle -top-2 -ml-2 absolute"
                />
              </div>

              <div className="ml-10">
                <div className="font-bold">
                  <Link to={`/${langKey}/tool-bank`} className="underline">
                    <FormattedMessage id="TOOL BANK" />
                  </Link>
                </div>
                <div className="italic md:mb-4"></div>
                <div className="mb-4 mt-2 md:hidden">
                  <div className="font-bold">2021</div>
                  <div className="text-xs">
                    <FormattedMessage id="July" />
                  </div>
                </div>
                {/* <div className="mb-10">Description of the project</div> */}
                <div className="mb-10"></div>
              </div>
            </div>
            {/* Timeline- Cori (italy) */}
            <div className="flex items-center relative">
              <div className="hidden md:block w-20">
                <div className="font-bold italic">2022</div>
                <div className="md:flex space-x-1 text-xs">
                  <div>
                    <FormattedMessage id="July" />
                  </div>
                </div>
              </div>

              <div className="border-r-2 border-black absolute h-full left-1 md:left-20 top-2 z-10">
                <FontAwesomeIcon
                  icon={faCircle}
                  size="1x"
                  className="fas fa-circle -top-2 -ml-2 absolute"
                />
              </div>

              <div className="ml-10">
                <div className="font-bold">
                  <FormattedMessage id="Learning & Training Activity in Cori (Italy)" />
                </div>
                <div className="italic md:mb-4 underline">
                  <a
                    className="italic md:mb-4 underline"
                    href={TM3Agenda}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="Agenda" />
                  </a>
                  <br />
                  <CustomModal pictures={imagesTM3} id="tm3" />
                </div>
                <div className="italic md:mb-4"></div>
                <div className="mb-4 mt-2 md:hidden">
                  <div className="font-bold">2022</div>
                </div>

                <div className="mb-10"></div>
              </div>
            </div>
            <div className="flex items-center relative">
              <div className="hidden md:block w-20">
                <div className="font-bold italic">2022</div>
                <div className="md:flex space-x-1 text-xs">
                  <div>
                    <FormattedMessage id="August" />
                  </div>
                </div>
              </div>
              <div className="border-r-2 border-black absolute h-full left-1 md:left-20 top-2 z-10">
                <FontAwesomeIcon
                  icon={faCircle}
                  size="1x"
                  className="fas fa-circle -top-2 -ml-2 absolute"
                />
              </div>
              <div className="ml-10">
                <div className="font-bold">
                  <Link
                    to={`/${langKey}/blended-course`}
                    className=" underline "
                  >
                    <FormattedMessage id="BLENDED COURSE" />
                  </Link>
                </div>
                <div className="mb-4 mt-2 md:hidden">
                  <div className="font-bold">2022</div>
                  <div className="text-xs">
                    <FormattedMessage id="August" />
                  </div>
                </div>
                <div className="mb-10"></div>
              </div>
            </div>
            <div className="flex items-center relative">
              <div className="hidden md:block w-20">
                <div className="font-bold italic">2022</div>
                <div className="md:flex space-x-1 text-xs">
                  <div>
                    <FormattedMessage id="October" />
                  </div>
                </div>
              </div>

              <div className="border-r-2 border-black absolute h-full left-1 md:left-20 top-2 z-10">
                <FontAwesomeIcon
                  icon={faCircle}
                  size="1x"
                  className="fas fa-circle -top-2 -ml-2 absolute"
                />
              </div>

              <div className="ml-10">
                <div className="font-bold">
                  <FormattedMessage id="Forth Transnational Meeting: Rijeka (Croatia)" />
                </div>
                <div className="italic md:mb-4 underline">
                  <a
                    className="italic md:mb-4 underline"
                    href={TM4Agenda}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="Agenda" />
                  </a>
                  <br />
                  <CustomModal pictures={imagesTM4} id="tm4" />
                </div>
                <div className="italic md:mb-4"></div>
                <div className="mb-4 mt-2 md:hidden">
                  <div className="font-bold">2022</div>
                  {/* <div className="text-xs">Abril - Junio</div> */}
                </div>
                {/* <div className="mb-10">DEscription of the project</div> */}
                <div className="mb-10"></div>
              </div>
            </div>
            <div className="flex items-center relative">
              <div className="hidden md:block w-20">
                <div className="font-bold italic">2023</div>
                <div className="md:flex space-x-1 text-xs">
                  <div>
                    <FormattedMessage id="February" />
                  </div>
                  {/* <div>-</div>
                    <div>Ago.</div> */}
                </div>
              </div>

              <div className="border-r-2 border-black absolute h-full left-1 md:left-20 top-2 z-10">
                <FontAwesomeIcon
                  icon={faCircle}
                  size="1x"
                  className="fas fa-circle -top-2 -ml-2 absolute"
                />
              </div>

              <div className="ml-10">
                <div className="font-bold">
                  <a
                    className="underline"
                    href={digitalBookEn}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="DIGITAL BOOK" />
                  </a>
                </div>
                <div className="mb-4 mt-2 md:hidden">
                  <div className="font-bold">2023</div>
                  <div className="text-xs">
                    <FormattedMessage id="February" />
                  </div>
                </div>
                <div className="mb-10"></div>
              </div>
            </div>
            <div className="flex items-center relative">
              <div className="hidden md:block w-20">
                <div className="font-bold italic">2023</div>
                <div className="md:flex space-x-1 text-xs">
                  <div>
                    <FormattedMessage id="May" />
                  </div>
                </div>
              </div>

              <div className="border-r-2 border-black absolute h-full left-1 md:left-20 top-2 z-10">
                <FontAwesomeIcon
                  icon={faCircle}
                  size="1x"
                  className="fas fa-circle -top-2 -ml-2 absolute"
                />
              </div>

              <div className="ml-10">
                <div className="font-bold">
                  <FormattedMessage id="Fifth Transnational Meeting: Aveiro, (Portugal)" />
                </div>
                <div className="italic md:mb-4 underline">
                  <a
                    className="italic md:mb-4 underline"
                    href={TM5Agenda}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="Agenda" />
                  </a>
                  <br />
                  <CustomModal pictures={imagesTM5} id="tm5" />
                </div>
                <div className="italic md:mb-4"></div>
                <div className="mb-4 mt-2 md:hidden">
                  <div className="font-bold">2023</div>
                  {/* <div className="text-xs">Abril - Junio</div> */}
                </div>
                {/* <div className="mb-10">DEscription of the project</div> */}
                <div className="mb-10"></div>
              </div>
            </div>
            <div className="flex items-center relative">
              <div className="hidden md:block w-20">
                <div className="font-bold italic">2023</div>
                <div className="md:flex space-x-1 text-xs">
                  <div>
                    <FormattedMessage id="August" />
                  </div>
                </div>
              </div>

              <div className="border-r-2 border-black absolute h-full left-1 md:left-20 top-2 z-10">
                <FontAwesomeIcon
                  icon={faCircle}
                  size="1x"
                  className="fas fa-circle -top-2 -ml-2 absolute"
                />
              </div>

              <div className="ml-10">
                <div className="font-bold">
                  <Link
                    to={`/${langKey}/geocaching-cultural-route`}
                    className=" underline "
                  >
                    <FormattedMessage id="GEOCACHING CULTURAL ROUTE" />
                  </Link>
                  {/*<a
                    className="underline"
                    href={`https://www.polygonal.ngo/compass-treasure-hunt-${langKey}`}
                  >
                    <FormattedMessage id="GEOCACHING CULTURAL ROUTE" />
                  </a>
                 */}
                </div>

                <div className="italic md:mb-4"></div>
                <div className="mb-4 mt-2 md:hidden">
                  <div className="font-bold">2023</div>
                  {/* <div className="text-xs">Abril - Junio</div> */}
                </div>
                {/* <div className="mb-10">DEscription of the project</div> */}
                <div className="mb-10"></div>
              </div>
            </div>
            <div className="flex items-center relative">
              <div className="hidden md:block w-20">
                <div className="font-bold italic">2023</div>
                <div className="md:flex space-x-1 text-xs">
                  <div>
                    <FormattedMessage id="April" />
                  </div>
                </div>
              </div>

              <div className="border-r-2 border-black absolute h-full left-1 md:left-20 top-2 z-10">
                <FontAwesomeIcon
                  icon={faCircle}
                  size="1x"
                  className="fas fa-circle -top-2 -ml-2 absolute"
                />
              </div>

              <div className="ml-10">
                <div className="italic">
                  <FormattedMessage id="Multiplier events" />
                  <div className="italic md:mb-4 underline">
                    <CustomModal pictures={imagesMEAll} id="meAll" />
                  </div>
                </div>

                <div className="mb-4 mt-2 md:hidden">
                  <div className="font-bold">2023</div>
                  {/* <div className="text-xs">Abril - Junio</div> */}
                </div>
                {/* <div className="mb-10">DEscription of the project</div> */}
                <div className="mb-10"></div>
              </div>
            </div>

          </div>
        </div>
      </section>

      {/* Tool Bank */}
      <section id="tool-bank" className="bg-gray-100 border-b py-8">
        <div className="container max-w-5xl mx-auto m-8 leading-relaxed">
          <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            <FormattedMessage id="TOOL BANK" />
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>

          <div className="flex flex-wrap">
            <div className="w-5/6 sm:w-1/2 p-6 text-gray-600 ">
              <p className="mb-10">
                <FormattedMessage id="The tool bank will include the practical examples of CLIL activities ready to be deployed by adult educators. These will be activities which will combine cultural heritage background and items to teach foreign languages. As such, it will become a great means to extend professional competences of adult educators in order to provide them with the necessary knowledge to improve language and intercultural skills of their adult learners and trigger their motivation to learn." />
              </p>
              <Link
                to={`/${langKey}/tool-bank`}
                className="mx-auto text-center bg-white text-black font-bold rounded-full mt-7 mb-10 py-4 px-8 shadow-lg"
              >
                {/* <FormattedMessage id="Know More" /> */}
                <FormattedMessage id="TOOL BANK" />
              </Link>
            </div>
            <div className="w-full sm:w-1/2 p-6">
              <img src={digiLearning} alt="" />
            </div>
          </div>
        </div>
      </section>

      {/* Blended Course */}
      <section id="blended-course" className="bg-white border-b py-8">
        <div className="container max-w-5xl mx-auto m-8 leading-relaxed">
          <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            <FormattedMessage id="BLENDED COURSE" />
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          <div className="flex flex-wrap flex-col-reverse sm:flex-row">
            <div className="w-full sm:w-1/2 p-6 mt-6">
              <img src={compassTeaching} alt="" />
            </div>
            <div className="w-full sm:w-1/2 p-6 mt-6">
              <div className="align-middle text-gray-600 mb-10">
                {/* <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                    Styled with Tailwind CSS
                  </h3> */}
                <p className=" mb-6">
                  <FormattedMessage id="If you are an educator working with older adults learning foreign languages, have a look at our new and innovative course “Cultural Narratives as a motivational trigger for learning in older learners”and learn everything you need to exploit cultural narrative and story-telling as a motivational trigger to engage older learners in lifelong learning." />
                </p>
                <p className="mb-2">
                  <FormattedMessage id="Take advantage of everything EU cultural heritage has to offer to keep your language students motivated with a CLIL approach! Learn how to activate your learners’ creativity and self-expression by creating safe learning environments where they will be able to share their life-stories and cultural narratives." />
                </p>
                <p className="mb-2">
                  <FormattedMessage id="START THE COURSE!" />
                </p>
              </div>
              <Link
                to={`/${langKey}/blended-course`}
                className="mx-auto text-center bg-white text-black font-bold rounded-full mt-7 mb-10 py-4 px-8 shadow-lg"
              >
                {/* <FormattedMessage id="Know More" /> */}
                <FormattedMessage id="BLENDED COURSE" />
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* DIGITAL BOOK */}
      <section id="digital-book" className="bg-gray-100 border-b py-8">
        <div className="container max-w-5xl mx-auto m-8 leading-relaxed">
          <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            <FormattedMessage id="DIGITAL BOOK" />
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>

          <div className="flex flex-wrap">
            <div className="w-5/6 sm:w-1/2 p-6 text-gray-600 ">
              <q lang={langKey}>
                <FormattedMessage id="Constructing Identity through Cultural memory" />
              </q>
              <p className="mt-2 mb-6">
                <FormattedMessage id="Only if you are familiar with the topic and you have gone through the course materials, you are ready to start experimenting with cultural narratives!" />
              </p>
              <p className="mb-6">
                <FormattedMessage id="Adults involved in COMPASS training activities have developed a Digital Book through a collaborative process in their local communities collecting and sharing knowledge and information about tangible and intangible culture elements in their communities and creating cultural narratives that will make all of us feel identified with the EU society." />
              </p>
              <p className="mb-2">
                <FormattedMessage id="These books will not only reflect the way older people relate and identify themselves with the cultural heritage of their communities but also serve as a practical tool for language learning." />
              </p>
              <p className="font-bold italic mb-8">
                <a
                  className="underline"
                  href={digitalBook[langKey]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="Download it here!" />
                </a>
              </p>
            </div>
            <div className="w-full sm:w-1/2 p-6">
              <img src={educationBooks} alt="women using books as a lader" />
            </div>
          </div>
        </div>
      </section>

      {/* Geocaching cultural route */}
      <section
        id="geocaching-cultural-route"
        className="bg-white border-b py-8"
      >
        <div className="container max-w-5xl mx-auto m-8 leading-relaxed">
          <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            <FormattedMessage id="GEOCACHING CULTURAL ROUTE" />
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          <div className="flex flex-wrap flex-col-reverse sm:flex-row">
            <div className="w-full sm:w-1/2 p-6 mt-6">
              <img src={onlineTest} alt="" />
            </div>
            <div className="w-full sm:w-1/2 p-6 mt-6">
              <div className="align-middle text-gray-600">
                {/* <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                    Styled with Tailwind CSS
                  </h3> */}
                <p className=" mb-6">
                  <FormattedMessage id="Have a look at our virtual geocaching cultural routes to discover European Cultural Heritage through the usage of fun digital technologies. Thanks to dynamical quests, quizzes, QR codes, digital maps and a multi-player chat-bot-based channel, you will be able to visit Europe from your home." />
                </p>
                <p className="mb-2">
                  <FormattedMessage id="Cultural Heritage sites have never been more accessible to all. Ready for some new experiences?" />
                </p>
                <Link
                  to={`/${langKey}/geocaching-cultural-route`}
                  className="mx-auto text-center bg-white text-black font-bold rounded-full mt-7 mb-10 py-4 px-8 shadow-lg"
                >
                  {/* <FormattedMessage id="Know More" /> */}
                  <FormattedMessage id="Click Here!" />
                </Link>

              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Logos partners */}
      <section id="partners" className="bg-white py-8">
        <div className="container mx-auto px-2 pt-4 pb-12  text-gray-800">
          <h1 className="w-full my-2 text-5xl font-bold leading-tight text-center">
            <FormattedMessage id="OUR PARTNERS" />
          </h1>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          <h2 className="text-center text-2xl">
            <FormattedMessage id="Would you like to meet the whole team?" />
            <br />
            <FormattedMessage id="These are the COMPASS partners:" />
          </h2>
          <div
            id="partners"
            className="grid justify-around pt-12 my-12 sm:my-4"
          >
            <Link to={`/${langKey}/partners/khvs`}>
              <img src={logoKhs} alt="" className="max-h-28" />
            </Link>
          </div>
          <hr />
          <div
            id="partners"
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-items-center pt-12 my-12 sm:my-4"
          >
            <Link to={`/${langKey}/partners/zini`}>
              <img src={logoZini} alt="" className="max-h-28" />
            </Link>
            <Link to={`/${langKey}/partners/ua`}>
              <img src={logoUa} alt="" className="max-h-28" />
            </Link>
            <Link to={`/${langKey}/partners/domspain`}>
              <img src={logoDomSpain} alt="" className="max-h-28" />
            </Link>{" "}
            <Link to={`/${langKey}/partners/polygonal`}>
              <img src={logoPolygonal} alt="" className="max-h-28" />
            </Link>
            <Link to={`/${langKey}/partners/dante`}>
              <img src={logoDante} alt="" className="max-h-28" />
            </Link>{" "}
            <Link to={`/${langKey}/partners/unave`}>
              <img src={logoUnave} alt="" className="max-h-28" />
            </Link>
          </div>
        </div>
      </section>

      {/* Waves SVG */}
      <svg
        className="wave-top"
        viewBox="0 0 1439 147"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1.000000, -14.000000)" fillRule="nonzero">
            <g className="wave" fill="#f8fafc">
              <path d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z"></path>
            </g>
            <g transform="translate(1.000000, 15.000000)" fill="#FFFFFF">
              <g transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) ">
                <path
                  d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                  opacity="0.100000001"
                ></path>
                <path
                  d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                  opacity="0.100000001"
                ></path>
                <path
                  d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                  opacity="0.200000003"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>

      {/* CTA block */}
      <section className="container mx-auto text-center py-6 mb-12">
        <h1 className="w-full my-2 text-5xl font-bold leading-tight text-center text-white">
          <FormattedMessage id="Hello! Let's talk!" />
        </h1>
        <div className="w-full mb-4">
          <div className="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
        </div>

        <h3 className="my-4 leading-tight">

          <FormattedMessage id="We would love to hear from you. Drop us , whether it is a comment, a question, a work proposition or just to say hello." />
        </h3>

        <form action={"data.site.siteMetadata.blog"}></form>

        <a className="mx-auto text-center bg-white text-black font-bold rounded-full mt-7 mb-10 py-4 px-8 shadow-lg" href="mailto:contact@eucompass.eu?subject='Hello from the web!'">  <FormattedMessage id="CONTACT US" /></a>







      </section>
    </>
  )
}

export default IndexPageComponent
